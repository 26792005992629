import React from 'react'
import Layout from '../components/layout'
// import { Link, navigate } from 'gatsby'

export default function DriverOfTheYear () {
  return (
    <Layout location='/doty/'>
      <section className='m-mb-0 doty'>
        <div className='mt'>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSecOy9fHOdnb_SimpnbXRMLpxwxqf1YZ1UAElXFMmLDNq9KtA/viewform?embedded=true"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>
        </div>
      </section>
    </Layout>
  )
}
